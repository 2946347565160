import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import visualcomposerLogo from '../../images/integration-logo-images/visualcomposer.png'

const VisualComposer= () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with Visual Composer"
                metaDescription="Optinly is the smart pop-up builder for your Visual Composer store. With Optinly, you can seamlessly attract leads to grow your email list and reduce cart abandonment."

                otherProductLogo={visualcomposerLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Visual Composer"
                homeTitle="Grow Your Email List with Visual Composer Popups"
                homeDescription="Integrate Optinly with your Visual Composer store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups."

                docsTitle="Increase your Visual Composer store email list "
                docsDescription="Best Visual Composer popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your Visual Composer store."

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. Visual Composer email popup through simple integeration. "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="Visual Composer Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best Visual Composer popup form to deliver customer-friendly popup. Select the best email popup for Visual Composer from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for Visual Composer Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your Visual Composer Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer Visual Composer email popup? "
                FAQAnswer1="Optinly offers multiple redaymade Visual Composer email popups. Also, it provides options to easily customize the popup depending upon the user. Visual Composer email popups are popups used in Visual Composer websites for collecting quality customer email address. "

                FAQQuestion2="How many types of Visual Composer popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for Visual Composer. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for Visual Composer stores?  "
                FAQAnswer3="Optinly have a free pricing plan that works on Visual Composer stores. Feel free to check out the entire pricing structure in Optinly pricing page. "

                FAQQuestion4="Is Visual Composer exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default VisualComposer
